import { Center, Container, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Badge from "../components/global/Badge";
import TextNumber from "../components/global/TextNumber";
import Tip from "../components/global/Tip";
import Title from "../components/global/Title";

function Statistics({ stats }) {
  const [period, setPeriod] = useState("today");
  return (
    <Container my="5">
      <Title
        mb={5}
        infos={
          <>
            <Text>Ici vous avez des statistiques qui vous permettent de mieux comprendre votre activité.</Text>
          </>
        }
      >
        Statistiques
      </Title>

      <Flex wrap={"wrap"}>
        <Badge
          onClick={() => {
            setPeriod("all");
          }}
          active={period === "all"}
        >
          Toute la période
        </Badge>
        <Badge
          onClick={() => {
            setPeriod("week");
          }}
          active={period === "week"}
        >
          Cette semaine
        </Badge>
        <Badge
          onClick={() => {
            setPeriod("yesterday");
          }}
          active={period === "yesterday"}
        >
          Hier
        </Badge>
        <Badge
          onClick={() => {
            setPeriod("today");
          }}
          active={period === "today"}
        >
          Aujourd'hui
        </Badge>
      </Flex>
      <Tip mt={4} closable="stats">Cliquez sur une période pour voir vos chiffres durant cette période</Tip>
      {stats.map((stat) => {
        return (
          <Center key={Math.random()} mt={5}>
            <TextNumber number={stat.value?.[period] || 0} text={stat.label} />
          </Center>
        );
      })}
    </Container>
  );
}

export default Statistics;

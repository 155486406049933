import { Alert, AlertDescription, AlertTitle, Box, Button, Text } from '@chakra-ui/react';
import React from 'react'

function PresentationBox() {
  return (
    <Box my={8} mt={4} px={5} textAlign={"justify"} fontSize={20}>
      <Text fontWeight={700}>De quoi s'agit-il et comment ça marche ?</Text>
      <br />{" "}
      <Text as="p" fontSize={15}>
        Publish est une application qui vous permet de gagner de l'argent sur vos réseaux sociaux en mettant en avant du contenu d'entreprises. <br />
      </Text>
      <Text fontWeight={600} mt="4">
        Comment ça marche concrètement ?
      </Text>
      <Text as="p" fontSize={15}>
        Des entreprises s'inscrivent sur la plateforme et créent des <strong>campagnes</strong>. Pour chacune d'elles, un prix est défini, un média (image ou vidéo), un lien et une description de ce qui est attendue sont fournis.
        <br />
        Votre objectif est alors de <strong>partager ces contenus</strong> sur votre statut whatsapp, un groupe facebook, bref tous vos réseaux.
        <br />
        <br />
        Pour chaque clic que vous obtiendrez suite à ces partages, l'entreprise vous versera une somme.
        <br />
        <Alert status="error" display={"inline-block"} mt={4}>
          <AlertTitle>Attention</AlertTitle>
          <AlertDescription>
            Soyez fair-play et ne trichez pas.
            <br />
            Chaque jour les clics sont analysés par des programmes et par des humains. En cas de suspicion de fraude, votre compte est alors banni et vous ne pourrez plus participer. Tous vos gains seront alors perdus. Donc n'utilisez pas de bots, de systèmes de clics multiples, de faux clics ou tout autre système dédié à gonfler artificiellement
            votre nombre de clics
          </AlertDescription>
        </Alert>
      </Text>
      <Text fontWeight={600} mt="4">
        Est-ce sûr ?
      </Text>
      <Text as="p" fontSize={15}>
        Certaines personnes ont cru au départ qu'il s'agit d'une arnaque. Ce n'est pas le cas.
        <br />
        L'inscription est totalement gratuite, donc on ne vous prend pas votre argent. Au contraire, on vous permet d'en gagner davantage en travaillant avec des entreprises prêtes à vous payer pour faire la communication de leur contenu autour de vous.
      </Text>
      <Text fontWeight={600} mt="4">
        Comment êtes vous rémunéré ?
      </Text>
      <Text as="p" fontSize={15}>
        Chaque jour votre solde est mis à jour (après vérification de l'activité de la veille). Une fois que ce solde atteint un seuil (fixé à 1000 FCFA aujourd'hui), il vous est alors possible d'initier un virement vers le numéro de téléphone avec lequel vous vous êtes inscrit.e.
      </Text>
      <Text fontWeight={600} mt="4">
        Besoin de plus d'informations ?
      </Text>
      <Text as="p" fontSize={15}>
        Vous avez envie de savoir autre chose, contactez l'équipe via Whatsapp au +237 6 95 76 25 95. Vous pouvez cliquez sur le bouton ci-dessous.
        <br />
        <br />
        <a href="https://t.me/+oY1BKLk3pGo2NGZk" target="_blank" rel="noreferrer">
          <Button colorScheme={"whatsapp"}>Contacter l'équipe</Button>
        </a>
      </Text>
    </Box>
  );
}

export default PresentationBox
import { CloseIcon } from "@chakra-ui/icons";
import { Alert, Button, Text } from "@chakra-ui/react";
import React, { useState } from "react";

function Tip({ children, closable = false, ...props }) {
  const [isVisible, setIsVisible] = useState(closable ? !localStorage.getItem(closable) : true);
  const close = () => {
    setIsVisible(false);
    localStorage.setItem(closable, "true");
  };
  return (
    isVisible && (
      <Alert {...props} borderRadius={"4px"} size="sm" status="warning" display={"inline-block"} w="auto">
        <Text as="small">💡 {children}</Text>
        {closable && (
          <Button size={"xs"} onClick={close} variant="ghost" colorScheme={"yellow"} ml={4}>
            <CloseIcon />
          </Button>
        )}
      </Alert>
    )
  );
}

export default Tip;

module.exports = {
  primaryColor: "#3FC250",
  darkGreen: "#0D3713",
  minWithdrawableMoney: 1000,
  transaction_status: {
    pending: "En traitement",
    failed: "Echouée",
    done: "Effectuée",
    all: "Toutes"
  },
};

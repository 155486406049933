import { Alert, AlertDescription, AlertIcon, AlertTitle, Container } from "@chakra-ui/react";
import React from "react";

function ErrorBox({ error }) {
  return (
    <Container my={4}>
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>{error?.title || "Error"}</AlertTitle>
        <AlertDescription>{error?.message}</AlertDescription>
      </Alert>
    </Container>
  );
}

export default ErrorBox;

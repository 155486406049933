import { Button, Container, FormControl, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import PhoneInput from "../components/global/PhoneInput";
import Title from "../components/global/Title";
import { updateUserInfos, useGlobalDispatch, useGlobalState } from "../store/GlobalStore";
import { primaryColor } from "../utils/constants";

function InformationsBox() {
  const { user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [fields, setFields] = useState({
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone_number: "",
    age: "",
    residence_country: "",
    target_country: "",
  });
  const updateField = (value, field) => {
    setFields({ ...fields, [field]: value });
  };

  const updateDatas = () => {
    updateUserInfos(dispatch, fields);
  };

  useEffect(() => {
    if (user)
      setFields({
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        phone_number: "",
        age: user.meta?.age || "",
        residence_country: user.meta?.residence_country || "",
        target_country: user.meta?.target_country || "",
        ...user,
      });
  }, [user]);

  return (
    <Container my={10}>
      <Title>Informations</Title>
      <Text>
        Ces informations seront nécessaires avant de vous envoyer vos gains.
      </Text>
      <Formik
        initialValues={fields}
        onSubmit={(values, actions) => {
          updateDatas();
        }}
      >
        {(props) => (
          <Form>
            <FormControl my={3}>
              <Input type="text" px={6} py={7} placeholder="Votre prénom" defaultValue={fields.first_name} onChange={(e) => updateField(e.target.value, "first_name")} />
            </FormControl>
            <FormControl my={3}>
              <Input type="text" px={6} py={7} placeholder="Votre nom" defaultValue={fields.last_name} onChange={(e) => updateField(e.target.value, "last_name")} />
            </FormControl>
            <FormControl my={3}>
              <Input type="email" px={6} py={7} placeholder="Votre email" defaultValue={fields.email} onChange={(e) => updateField(e.target.value, "email")} />
            </FormControl>
            <FormControl my={3}>
              <PhoneInput disabled type="phone_number" placeholder="Votre numéro de téléphone" value={fields.phone_number} onChange={(value) => updateField(value, "phone_number")} />
            </FormControl>
            <FormControl my={3}>
              <InputGroup>
                <Input px={6} py={7} type="number" value={fields.age} onChange={(e) => updateField(e.target.value, "age")} placeholder="Votre age" />
                <InputRightElement px={10} py={8}>
                  <Text fontWeight={800} color={primaryColor} textTransform="uppercase">
                    Ans
                  </Text>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl my={3}>
              <Input type="text" px={6} py={7} placeholder="Dans quel pays résidez-vous ?" defaultValue={fields.residence_country} onChange={(e) => updateField(e.target.value, "residence_country")} />
            </FormControl>
            <FormControl my={3}>
              <Input type="text" px={6} py={7} placeholder="Dans quel pays réside la majorité de vos contacts ?" defaultValue={fields.target_country} onChange={(e) => updateField(e.target.value, "target_country")} />
            </FormControl>
            <FormControl>
              <Button type="submit" colorScheme={"whatsapp"} size="lg" w="100%">
                Enregistrer les modifications
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default InformationsBox;

import { Center } from "@chakra-ui/react";
import React from "react";
import { useGlobalState } from "../../store/GlobalStore";
import { primaryColor } from "../../utils/constants";

function Badge({ children, active, ...props }) {
  const { color_theme } = useGlobalState();
  const lightModeColor = active ? "white" : "#999";
  const darkModeColor = active ? "#333" : "#AAA";
  const lightModeBgColor = active ? primaryColor : "#EDEDED";
  const darkModeBgColor = active ? primaryColor : "#333";
  return (
    <Center cursor={"pointer"} mr={"2"} my={"1"} fontSize=".7rem" borderRadius={"100px"} px={4} py={1} bgColor={color_theme === "light" ? lightModeBgColor : darkModeBgColor} color={color_theme === "light" ? lightModeColor : darkModeColor} fontWeight={"500"} {...props}>
      {children}
    </Center>
  );
}

export default Badge;

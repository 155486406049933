import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Center,
  CloseButton,
  Container,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CopyInput from "../components/global/CopyInput";
import Tip from "../components/global/Tip";
import Title from "../components/global/Title";

function ShareBox({ campaign, user }) {
  const toast = useToast();
  const urlToShare = `${
    campaign.meta?.short_url || `https://lnk.publish.cm/${campaign.uuid}`
  }/${user.uuid}`;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const share = async (item) => {
    // Check if the Web Share API is available
    if (!navigator.share) {
      try {
        setSharedMedia(true);
        const text = encodeURIComponent(
          `${campaign.description} ${urlToShare}`
        );
        window.open(`https://wa.me/?text=${text}`, "_blank");

        await navigator.clipboard.writeText(urlToShare);
        toast({
          title: "Lien copié",
          description: "Le lien a été copié dans votre presse-papier",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } catch (clipboardError) {
        console.error("Failed to copy to clipboard:", clipboardError);
        toast({
          title: "Erreur",
          description: "Une erreur s'est produite lors de la copie du lien.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      return;
    }

    if (item === "media" && campaign?.media?.original_url) {
      try {
        setSharedMedia(true);

        const response = await fetch(campaign.original_media);

        if (!response.ok) {
          throw new Error(`Failed to fetch media: ${response.statusText}`);
        }

        const blob = await response.blob();
        const filesArray = [
          new File([blob], "affiche.jpg", {
            type: blob.type || "image/jpeg",
          }),
        ];

        const shareData = {
          files: filesArray,
          url: `${urlToShare}`,
          title: `${campaign.description} ${urlToShare}`,
          text: `${campaign.description} ${urlToShare}`,
        };

        try {
          await navigator.share(shareData);
        } catch (shareError) {
          console.error("Failed to share media:", shareError);
          toast({
            title: "Erreur",
            description: "Une erreur s'est produite lors du partage du média.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (fetchError) {
        console.error("Failed to fetch media or create file:", fetchError);
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produite lors de la récupération du média.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      try {
        await navigator.share({
          text: `${campaign.description}

${urlToShare}`,
        });
      } catch (shareError) {
        console.error("Failed to share URL:", shareError);
        toast({
          title: "Erreur",
          description: "Une erreur s'est produite lors du partage du lien.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  const [sharedMedia, setSharedMedia] = useState(false);
  const [closeInfos, setCloseInfos] = useState(false);
  const isVideo =
    false ||
    (campaign?.media.mime_type || campaign?.media.type).includes("video");
  const onCloseInfos = () => {
    setCloseInfos(true);
  };
  return (
    <Container my={8}>
      <Title>Contenus à partager</Title>
      {/* <Text>
        Vous avez rejoint la campagne {campaign.name}. Si vous souhaitez avoir plus d'informations sur celle-ci,{" "}
        <Text as="span" cursor={"pointer"} onClick={onOpen} color={primaryColor}>
          cliquez ici
        </Text>
        .
      </Text> */}
      {!closeInfos && (
        <Alert
          my={4}
          fontSize={15}
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <CloseButton
            alignSelf="flex-end"
            position="relative"
            right={-1}
            top={-1}
            onClick={onCloseInfos}
          />
          <Box>
            <AlertTitle>IMPORTANT</AlertTitle>
            <AlertDescription>
              Pour partager ce média, maintenez votre doigt appuyé. Lorsqu'une
              fenetre apparait, cliquez sur <strong>partager l'image</strong> et
              partagez en statut Whatsapp ou sur le réseau social de votre
              choix. Vous devrez ensuite <strong>partager le lien</strong>
            </AlertDescription>
          </Box>
        </Alert>
      )}
      <Text>Les contenus à partager pour cette campagne sont les suivants</Text>
      <br />
      <Text as="strong">Etape 1 : Partager l'image ou la video</Text>
      <br />
      <Text lineHeight={"20px"} display={"inline-block"} mb={3} as="small">
        Vous devez partager ce média et le lien qui est en dessous.
      </Text>
      <Flex justifyContent="center">
        <Box overflow="hidden" height="200px" cursor={"pointer"}>
          <Center px={10} textAlign="center" width="100%" height="100%">
            {campaign?.media ? (
              <Box height="200px" bgColor={"#EEE"} overflow="hidden">
                {isVideo ? (
                  <video
                    src={campaign?.media.original_url}
                    controls
                    style={{ height: "100%" }}
                  />
                ) : (
                  <Image
                    objectFit={"contain"}
                    src={campaign?.media.original_url}
                    alt=""
                    w="100%"
                    h="100%"
                    objectPosition={"center"}
                  />
                )}
              </Box>
            ) : (
              <>Aucun média disponible</>
            )}
          </Center>
          {/* <Image src={campaign?.campaign?.media?.original_url} alt={""} w={"300px"} /> */}
        </Box>
      </Flex>

      {/* <Center my={5}>
        {isVideo ? (
          <Tip>Téléchargez la video puis partagez la</Tip>
        ) : (
          <Tip>Maintenez votre doigt sur l'image pour partager</Tip>
        )}
      </Center> */}
      <Box textAlign="center">
        <Button
          colorScheme={"whatsapp"}
          my={2}
          mb={4}
          onClick={() => share("media")}
        >
          Partager sur les réseaux
        </Button>
      </Box>
      <br />
      <Text as="strong">Etape 2 : Partager le texte avec lien</Text>
      <br />
      <br />
      <Text lineHeight={"20px"} display={"inline-block"} mb={3}>
        {campaign.description}
      </Text>
      <br />
      <Text as="strong">Lien</Text>
      <br />
      {/* <Text lineHeight={"20px"} display={"inline-block"} mb={3} as="small">Notez bien que vous ne gagnerez l'argent que lorsque des personnes différentes cliqueront sur votre lien. Il vous faut alors trouver la bonne approche pour emmener vos contacts à cliquer sur le lien et acheter les produits ou services de l'entreprise.</Text> */}
      <Text lineHeight={"20px"} display={"inline-block"} mb={3} as="small">
        Notez bien que vous ne gagnerez l'argent que lorsque des{" "}
        <strong>personnes différentes cliqueront</strong> sur votre lien.
      </Text>
      <CopyInput text={urlToShare} onCopy={(e) => {}} />

      <Box textAlign="center">
        <Button
          colorScheme={"whatsapp"}
          my={2}
          mb={4}
          onClick={() =>
            campaign?.media && !sharedMedia
              ? toast({
                  title: "Partagez l'image ou la video",
                  description:
                    "Vous devez d'abord partager l'image ou la video avant de partager le lien.",
                  status: "error",
                  duration: 4000,
                  isClosable: true,
                })
              : share("link")
          }
        >
          Partager sur les réseaux
        </Button>
      </Box>
      <br />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{campaign.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{campaign.description}</ModalBody>
          <ModalFooter>
            <Button colorScheme="whatsapp" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default ShareBox;

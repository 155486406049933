import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  Image,
  Input,
  Text,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import LayoutAuth from "../components/global/LayoutAuth";
import PhoneInput from "../components/global/PhoneInput";
import Footer from "../partials/Footer";
import {
  changeUserPassword,
  getUser,
  useGlobalDispatch,
  useGlobalState,
} from "../store/GlobalStore";
import { primaryColor } from "../utils/constants";

function ForgottenPassword() {
  const { color_theme, loading, error, user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [fields, setFields] = useState({
    tel: "",
    password: "",
    confirm_password: "",
    name: "",
  });
  const toast = useToast();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUser(dispatch);
    }
  }, []);

  const changeField = (value, field) => {
    setFields({ ...fields, [field]: value });
  };
  const changePassword = () => {
    if (fields.confirm_password === fields.password) {
      changeUserPassword(dispatch, fields);
    } else {
      toast({
        title: "Error",
        description: "Les mots de passe ne sont pas les mêmes",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (user && !error) {
    return <Navigate to="/" />;
  }

  return (
    <LayoutAuth>
      <Container>
        <Box w="100%" py={18}>
          <Center mt={5}>
            <Image
              src={color_theme === "light" ? "/logo.png" : "/logo_white.png"}
              height={"3rem"}
              alt="Logo de Publish"
            />
          </Center>
          <Box textAlign={"center"} mt={8} px={5}>
            <span>
              Gagnez de l'argent en partageant des contenus sur vos réseaux :{" "}
              <br />
              <strong>Whatsapp, Dikalo, Twitter, Facebook, etc</strong>
            </span>
          </Box>
        </Box>
        <Box textAlign={"center"} px={5}>
          <Text fontSize="4xl" fontWeight={"800"} my={10}>
            Mot de passe oublié
          </Text>

          <FormControl my="4">
            <PhoneInput
              placeholder="Votre numéro de téléphone"
              value={fields.tel}
              onChange={(v) => changeField(v, "tel")}
            />
          </FormControl>
          <FormControl my="4">
            <Input
              type="password"
              px={6}
              py={7}
              placeholder="Mot de passe"
              onChange={(e) => changeField(e.target.value, "password")}
            />
          </FormControl>
          <FormControl my="4">
            <Input
              type="password"
              px={6}
              py={7}
              placeholder="Confirmation de mot de passe"
              onChange={(e) => changeField(e.target.value, "confirm_password")}
            />
          </FormControl>

          <Button
            bgColor={primaryColor}
            onClick={changePassword}
            fontWeight={500}
            width="100%"
            color="white"
            py={7}
            isLoading={loading}
          >
            Changer mon mot de passe{" "}
          </Button>
        </Box>
        <Box my={8} mt={4} px={5} textAlign={"right"} fontSize={20}>
          <Text as="small">
            Vous avez déjà un compte ?{" "}
            <Link to={"/login"}>
              <Text color={primaryColor} as="span">
                connectez-vous
              </Text>
            </Link>
            .
          </Text>
        </Box>
      </Container>
      <Footer />
    </LayoutAuth>
  );
}

export default ForgottenPassword;

import { Box, Input } from "@chakra-ui/react";
import React from "react";
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const ContainerComponent = (props) => {
  return <Box border="1px" borderRadius={4} px={3} py={2} borderColor={"var(--chakra-colors-chakra-border-color)"} {...props} />;
};

function PhoneInput(props) {
  return <PhoneInputWithCountrySelect containerComponent={ContainerComponent} border={"none"} inputComponent={Input} defaultCountry="CM" error={props.value ? (isValidPhoneNumber(props.value) ? undefined : "Invalid phone number") : "Phone number required"} {...props} />;
}

export default PhoneInput;

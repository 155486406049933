import { Box, Center, Container, Divider, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import packageJson from "../../package.json";

function Footer() {
  return (
    <Container mt="20" mb="8">
      <Divider my={4} />
      <Center>
        <Box textAlign={"center"}>
          <Text my="2">
            <Link to="/privacy-policy">Politique de confidentialité</Link>
          </Text>
          <Text my="2">
            <Link to="/legal">Conditions d'utilisation</Link>
          </Text>
        </Box>{" "}
      </Center>
      <Center mt="8">Version {packageJson.version}</Center>
      <Center my="2">
        Tous Droits Réservés &#169; Publish. {new Date().getFullYear()}
      </Center>
    </Container>
  );
}

export default Footer;

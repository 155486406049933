import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { darkGreen, minWithdrawableMoney } from "../../utils/constants";

function RemoveMoneyButton({ user, initiatePayment }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const paymentModes = [
    { value: "MTN", label: "MTN Mobile Money" },
    { value: "ORANGE", label: "Orange Money" },
  ];
  const [fields, setFields] = useState({
    phone_number: user.phone_number,
    service: "MTN",
    currency: "XAF",
    amount: user.balance > minWithdrawableMoney ? minWithdrawableMoney : 0,
  });
  const updateField = (value, field) => {
    setFields({ ...fields, [field]: value });
  };
  const withdrawMoney = (e) => {
    initiatePayment(fields, onClose);
  };
  const canWithdraw = fields.amount >= minWithdrawableMoney && fields.amount <= user.balance && user.is_verified;
  return (
    <>
      <Button size="sm" onClick={onOpen}>
        Effectuer un retrait
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Retirer de l'argent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {user.is_verified ? (
              user.balance >= minWithdrawableMoney ? (
                <>
                  <FormControl my="3">
                    <Text fontSize={15}>Numéro de téléphone</Text>
                    <Input
                      type="text"
                      px={6}
                      py={7}
                      defaultValue={user.phone_number}
                      disabled
                    />
                  </FormControl>
                  <FormControl my={8}>
                    <Text fontSize={15}>Mode de paiement</Text>
                    <Select
                      size="lg"
                      defaultValue={fields.service}
                      onChange={(e) => updateField(e.target.value, "service")}
                    >
                      {paymentModes.map((mode) => {
                        return (
                          <option
                            key={"option" + mode.value}
                            value={mode.value}
                          >
                            {mode.label}
                          </option>
                        );
                      })}
                    </Select>
                    <Text as="small">
                      Des frais de transaction peuvent s'appliquer lors du
                      paiement.
                    </Text>
                  </FormControl>
                  <FormControl my={3}>
                    <Text fontSize={15}>Combien souhaitez-vous retirer ?</Text>
                    <InputGroup>
                      <Input
                        onChange={(e) => updateField(e.target.value, "amount")}
                        defaultValue={fields.amount}
                        placeholder="Entrez un montant"
                        max={user.balance}
                        min={minWithdrawableMoney}
                      />
                      <InputRightElement>
                        <Text
                          as="span"
                          fontWeight={700}
                          color={darkGreen}
                          pr={4}
                        >
                          XAF
                        </Text>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Text as="small">
                    L'argent sera envoyé au numéro de téléphone avec lequel vous
                    vous êtes inscrit.e, rassurez vous que toutes vos
                    informations sont correctes pour éviter des soucis lors du
                    virement.
                  </Text>
                </>
              ) : (
                <Text textAlign={"center"}>
                  Malheureusement, vous n'avez pas assez de fonds. Vous pourrez
                  retirer à partir de {minWithdrawableMoney} FCFA et après avoir
                  complété et vérifié vos informations de profil.
                </Text>
              )
            ) : (
              <Text textAlign={"center"}>
                Votre compte doit être vérifié pour pouvoir retirer de l'argent.
                Veuillez vérifier votre compte pour continuer.
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
            <Button
              colorScheme="whatsapp"
              mr={3}
              onClick={canWithdraw ? withdrawMoney : onClose}
              disabled={
                fields.amount < minWithdrawableMoney ||
                fields.amount > user.balance
              }
            >
              {
            canWithdraw ? "Valider" : "D'accord"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RemoveMoneyButton;

import { HStack, Image, Text } from '@chakra-ui/react'
import React from 'react'

function IconText({icon, text}) {
  return (
    <HStack>
        <Image src={icon} alt="icon" />
        <Text fontSize={"12px"} fontWeight="500" >{text}</Text>
    </HStack>
  )
}

export default IconText
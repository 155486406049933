import { Container, HStack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import CampaignCard from "../components/campaigns/CampaignCard";
import Title from "../components/global/Title";
import LoadingPage from "../pages/LoadingPage";

function CampaignsList({
  empty,
  description,
  loading,
  title,
  campaigns: _campaigns,
}) {
  const [isActive, setIsActive] = useState(true);
  const campaigns = _campaigns
    .sort((a, b) => {
      return a.id < b.id ? 1 : -1;
    })
    .filter((campaign) =>
      isActive ? campaign.is_active === 1 : campaign.is_active === 0
    );
  console.log(campaigns);
  return (
    <Container my="20">
      <Title
        infos={
          <>
            <Text fontWeight="600">Campagnes en cours</Text>

            <Text>
              Les campagnes en cours sont toutes les campagnes que vous avez
              rejoins.
            </Text>

            <Text mt="4" fontWeight="600">
              Campagnes disponibles
            </Text>
            <Text>
              Les campagnes disponibles sont toutes les campagnes qui ont été
              créées par des entreprises et qui sont disponibles pour être
              rejoints.
            </Text>
          </>
        }
      >
        {title}
      </Title>
      {campaigns && (
        <HStack>
          <Text
            onClick={() => setIsActive(true)}
            mr="4"
            cursor={"pointer"}
            px="2"
            fontWeight={isActive ? "bold" : "normal"}
            borderBottom={
              isActive ? "3px #3FC250 solid" : "3px transparent solid"
            }
          >
            Actives
          </Text>
          <Text
            cursor={"pointer"}
            px="2"
            onClick={() => setIsActive(false)}
            fontWeight={!isActive ? "bold" : "normal"}
            borderBottom={
              !isActive ? "3px #3FC250 solid" : "3px transparent solid"
            }
          >
            Inactives
          </Text>
        </HStack>
      )}
      {description && <Text>{description}</Text>}
      {loading && <LoadingPage h={"20vh"} />}
      {campaigns && campaigns.length === 0
        ? empty
        : campaigns.map((campaign) => {
            return <CampaignCard key={Math.random()} campaign={campaign} />;
          })}
    </Container>
  );
}

export default CampaignsList;

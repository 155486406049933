import { Text } from "@chakra-ui/react";
import React from "react";
import InfoIconModal from "./InfoIconModal";

function Title({ children, infos, ...props }) {
  return (
    <Text className="title" fontSize={"1.5rem"} mb="10" fontWeight="700" {...props}>
      {children} {infos && <InfoIconModal>{infos}</InfoIconModal>}
    </Text>
  );
}

export default Title;

import { Box, Button, Container, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Layout from "../components/global/Layout";
import TextNumber from "../components/global/TextNumber";
import ActionsButtonBox from "../partials/ActionsButtonBox";
import Breadcrumb from "../partials/Breadcrumb";
import InformationsBox from "../partials/InformationsBox";
import TransactionsBox from "../partials/TransactionsBox";
import {
  getAllTransactions,
  getUser,
  useGlobalDispatch,
  useGlobalState,
} from "../store/GlobalStore";

function Account() {
  const { user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [stats, setStats] = useState([]);

  const breadcrumbItems = [
    {
      name: "Accueil",
      path: "/",
    },
    {
      name: "Account",
      path: "/account",
    },
  ];
  useEffect(() => {
    getUser(dispatch);
    getAllTransactions(dispatch);
  }, []);

  useEffect(() => {
    if (user && user.stats) {
      setStats([
        {
          label: "FCFA gagnés",
          value: user.stats.total_earned,
        },
        {
          label: "Clics recoltés",
          value: user.stats.total_clics,
        },
        {
          label: "Campagnes",
          value: user.stats.total_campaigns,
        },
      ]);
    }
  }, [user]);
  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />
      {user && (
        <Container>
          <Text fontWeight={700} fontSize={30}>
            Salut {user.first_name || user.name},
          </Text>
          <Text>Vous avez,</Text>
          <TextNumber
            my={10}
            number={user.balance + " XAF"}
            text={"disponibles"}
          />
          <br />
          {user.verification_status === "rejected" ? (
            <Text
              fontWeight={700}
              fontSize={20}
              textAlign="center"
              mb={5}
              color="red"
            >
              Votre vérification a été rejetée ❌
              <br />
              <Text as="small">Raison: {user.verification_message}</Text>
            </Text>
          ) : null}
          {user.verification_status === "pending" ? (
            <Text fontWeight={700} fontSize={20} textAlign="center" mb={5}>
              Votre vérification est en cours ⌛️
            </Text>
          ) : null}
          {user.is_verified ? (
            <Text fontWeight={700} fontSize={20} textAlign="center" mb={5}>
              Votre compte est vérifié ✅
            </Text>
          ) : (
            <Box
              style={{
                border: "2px dashed #9ae6b4",
                padding: "20px",
                textAlign: "center",
                borderRadius: "10px",
              }}
            >
              <Text>
                Pour des raisons de sécurité, nous avons besoin de vérifier
                votre identité.
              </Text>
              <a
                href="/verification"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button colorScheme="whatsapp" size="md" mt={5}>
                  Procéder à la vérification d'identité
                </Button>
              </a>
            </Box>
          )}
        </Container>
      )}

      {/* <Statistics stats={stats} /> */}
      {user.last_name && user.email ? (
        <>
          <TransactionsBox />
          <InformationsBox />
        </>
      ) : (
        <>
          <InformationsBox />
          <TransactionsBox />
        </>
      )}
      <ActionsButtonBox />
    </Layout>
  );
}

export default Account;

import { Box, Button, Center, Container, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Layout from "../components/global/Layout";
import Title from "../components/global/Title";
import Breadcrumb from "../partials/Breadcrumb";
import CampaignsList from "../partials/CampaignsList";
import Statistics from "../partials/Statistics";
import {
  getCampaigns,
  useCampaignDispatch,
  useCampaignState,
} from "../store/CampaignStore";
import { useGlobalState } from "../store/GlobalStore";
import LoadingPage from "./LoadingPage";

function Home() {
  const { user } = useGlobalState();
  const [stats, setStats] = useState([]);
  const [showNotification, setShowNotification] = useState(
    !localStorage.getItem("hideNotification-2022-18-11.1")
  );
  const { loading, campaigns } = useCampaignState();
  const dispatchCampaigns = useCampaignDispatch();

  useEffect(() => {
    getCampaigns(dispatchCampaigns);
  }, [dispatchCampaigns]);

  const breadcrumbItems = [
    {
      name: "Accueil",
      path: "/",
    },
  ];

  useEffect(() => {
    if (user) {
      setStats([
        {
          label: "Clics",
          value: user.stats.total_clics,
        },
        {
          label: "FCFA",
          value: user.stats.total_earned,
        },
        {
          label: "Campagnes",
          value: user.stats.total_campaigns,
        },
      ]);
    }
  }, [user]);
  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />
      <Container mb="5">
        <Text fontWeight={700}>Salut {user.first_name || user.name}, </Text>
      </Container>
      {showNotification && new Date(user.created_at).getDate() < 16 && (
        <Container mb={"10"}>
          <Box>
            <Title>Notifications</Title>
            <Text fontWeight={"bold"}>
              <Text color="whatsapp.500" as="span">
                NE MANQUEZ AUCUNE CAMPAGNE.
              </Text>
            </Text>
            <Text mt="3">
              <a
                href="https://t.me/+oY1BKLk3pGo2NGZk"
                onClick={() => {
                  setShowNotification(false);
                  localStorage.setItem("hideNotification-2022-18-11.1", "true");
                }}
              >
                <Button
                  colorScheme={"telegram"}
                  display="inline-block"
                  maxW="100%"
                >
                  Rejoignez notre groupe Telegram
                </Button>
              </a>
            </Text>
          </Box>
          {/* <Box my="4" textAlign={"center"}>
            <Button
              onClick={() => {
                setShowNotification(false);
                localStorage.setItem("hideNotification-2022-18-11.1", "true");
              }}
              colorScheme={"whatsapp"}
            >
              J'ai compris, ce message peut être masqué.
            </Button>
          </Box> */}
        </Container>
      )}
      <Statistics stats={stats} />
      {!campaigns && <LoadingPage h="10vh" />}
      {campaigns && (
        <CampaignsList
          title="Campagnes en cours"
          empty={
            <Center>
              <Text>
                Vous n'avez encore rejoins aucune campagne. Sélectionnez une
                campagne dans la liste des campagnes disponibles et cliquez sur
                rejoindre la campagne pour pouvoir commencer à partager et
                gagner de l'argent.
              </Text>
            </Center>
          }
          loading={loading}
          campaigns={campaigns.in_progress}
        />
      )}
      {campaigns && (
        <CampaignsList
          empty={
            <Center>
              <Text>Aucune campagne n'est disponible pour le moment</Text>
            </Center>
          }
          description={
            "Ici vous avez la liste des campagnes créées par les entreprises. Vous pouvez cliquer sur l'une d'entre elle pour la rejoindre"
          }
          title="Campagnes disponibles"
          loading={loading}
          campaigns={campaigns.available}
        />
      )}

      <Container my="20">
        <Title>Une question ?</Title>
        <Text>
          Si vous avez des questions, n'hésitez pas à nous contacter
          <a href="https://t.me/+oY1BKLk3pGo2NGZk">
            <strong>sur Telegram</strong>
          </a>
        </Text>
      </Container>
    </Layout>
  );
}

export default Home;

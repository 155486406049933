import axios from "axios";
import React from "react";
import { api } from "../configs/api";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

async function getCampaign(dispatch, campaignUuid) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    const response = await axios.get(`${api}/campaigns/${campaignUuid}`, { headers: { Authorization: "Bearer " + token } });

    dispatch({ type: "campaign", payload: response.data });
  } catch (error) {
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when getting campaign",
        error,
      },
    });
  }
}

async function joinCampaign(dispatch, campaignUuid) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    const response = await axios.post(`${api}/campaigns/${campaignUuid}/join`, {}, { headers: { Authorization: "Bearer " + token } });
    console.log(response.data);
    dispatch({
      type: "campaign",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when joining this campaign. ",
        error,
      },
    });
  }
}

async function getCampaigns(dispatch) {
  const token = localStorage.getItem("token");
  dispatch({ type: "loading", payload: true });
  try {
    let response = await axios.get(`${api}/campaigns`, { headers: { Authorization: "Bearer " + token } });
    const my_campaigns = response.data;
    response = await axios.get(`${api}/campaigns/all`, { headers: { Authorization: "Bearer " + token } });
    const all_campaigns = response.data;
    dispatch({
      type: "campaigns",
      payload: {
        in_progress: my_campaigns,
        available: all_campaigns.filter((c) => !my_campaigns.map((c) => c.uuid).includes(c.uuid)),
      },
    });
  } catch (error) {
    console.log("cant get campaigns, ", error);
    dispatch({
      type: "error",
      payload: {
        message: "Something went wrong when getting campaigns",
        error,
      },
    });
  }
}

function commentReducer(state, action) {
  switch (action.type) {
    case "campaign": {
      return { ...state, campaign: action.payload, loading: false };
    }
    case "campaigns": {
      return { ...state, campaigns: action.payload, loading: false };
    }
    case "error":
      return { ...state, error: action.payload, loading: false };
    case "loading":
      return { ...state, loading: action.payload };
    default: {
      state[action.type] = action.payload;
      return { ...state };
    }
  }
}

function CampaignProvider({ children }) {
  const [state, dispatch] = React.useReducer(commentReducer, {
    loading: undefined,
    error: undefined,
    campaign: undefined,
    campaigns: undefined,
  });
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

function useCampaignState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCampaignState must be used within a CampaignProvider");
  }
  return context;
}

function useCampaignDispatch() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useCampaignDispatch must be used within a CampaignProvider");
  }
  return context;
}

export { CampaignProvider, useCampaignState, useCampaignDispatch, getCampaign, getCampaigns, joinCampaign };

import { Text, VStack } from "@chakra-ui/react";
import React from "react";
import { primaryColor } from "../../utils/constants";

function TextNumber({ number, text , ...props}) {
  return <VStack {...props}>
    <Text fontSize={"3rem"} color={primaryColor} fontWeight={700}>{number}</Text>
    <Text mt={0} as="span" fontSize={'1.5rem'}>{text}</Text>
  </VStack>;
}

export default TextNumber;

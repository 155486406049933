import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import {
  useCampaignDispatch,
  useCampaignState,
} from "../../store/CampaignStore";
import { useGlobalDispatch, useGlobalState } from "../../store/GlobalStore";

function Layout(props) {
  const navigate = useNavigate();
  const toast = useToast();
  const { error, notify } = useGlobalState();
  const { redirect_to, notify: notifyCampaign, iframe } = useCampaignState();
  const dispatch = useGlobalDispatch();
  const dispatchCampaign = useCampaignDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (redirect_to) {
      navigate(redirect_to);
      dispatchCampaign({ type: "redirect_to", payload: undefined });
    }
  }, [redirect_to]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    if (notify || notifyCampaign) {
      toast({
        title: (notify || notifyCampaign).title || "Succès",
        description: (notify || notifyCampaign).message,
        status: (notify || notifyCampaign).status || "success",
        duration: 9000,
        isClosable: true,
      });
      dispatch({ type: "notify", payload: undefined });
      dispatchCampaign({ type: "notify", payload: undefined });
    }
  }, [notify?.message, notify, notifyCampaign]);

  useEffect(() => {
    if (iframe) {
      onOpen();
    }
  }, [iframe]);

  return (
    <>
      <Header />
      {props.children}
      {iframe && (
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader></ModalHeader> */}
            <ModalCloseButton />
            <ModalBody p={0}>
              <iframe
                src={iframe.src}
                title={iframe.title}
                style={{ width: "100vw", height: "100vh" }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <Footer />
    </>
  );
}

export default Layout;

import { primaryColor } from "./constants";

export function transactionStatusColor(status) {
  switch (status) {
    case "pending":
      return "#616161";
    case "done":
      return primaryColor;
    case "failed":
      return "#FF1111";
    default:
      return "#616161";
  }
}

export function mapperRoute(route) {
  return route;
  // if (process.env.NODE_ENV !== "production") {
  //   return route + ".json";
  // }
  // return route;
}

export function mapperUserFields(fields) {
  let userInfos = {
    ...fields,
    meta: {
      age: fields.age,
      target_country: fields.target_country,
      residence_country: fields.residence_country,
    },
  };
  return userInfos;
}


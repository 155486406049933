import { Flex, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { primaryColor } from "../../utils/constants";

function CopyInput(props) {
  const toast = useToast();
  const { text, onCopy } = props;
  const onCopyMethod = (e) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Lien copié.",
      //   description: "We've created your account for you.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
    onCopy(e);
  };
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} border="1px" borderRadius={4} px={3} py={3} borderColor={"var(--chakra-colors-chakra-border-color)"} {...props}>
      <Text textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"}>{text}</Text>
      <Text color={primaryColor} onClick={onCopyMethod} fontSize={14} fontWeight={600} cursor="pointer">
        COPIER
      </Text>
    </Flex>
  );
}

export default CopyInput;

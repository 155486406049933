import { useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useGlobalDispatch, useGlobalState } from "../../store/GlobalStore";

function LayoutAuth(props) {
  const toast = useToast();
  const { error, notify } = useGlobalState();
  const dispatch = useGlobalDispatch();

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    if (notify) {
      toast({
        title: notify.title || "Succès",
        description: notify.message,
        status: notify.status || "success",
        duration: 9000,
        isClosable: true,
      });
      dispatch({ type: "notify", payload: undefined });
    }
  }, [notify?.message, notify]);

  return <>{props.children}</>;
}

export default LayoutAuth;

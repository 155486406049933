import { Button, Container, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Layout from "../components/global/Layout";
import {
  useGlobalDispatch,
  useGlobalState,
  userVerification,
} from "../store/GlobalStore";
import Breadcrumb from "../partials/Breadcrumb";

function Verification() {
  const { user, loading, error } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [identity, setIdentity] = useState(null);
  const [selfie, setSelfie] = useState(null);

  const [step, setStep] = useState(1);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          Object.assign(file, {
            original_url: URL.createObjectURL(file),
            buffer: binaryStr,
          });
          if (step === 2) setSelfie(file);
          if (step === 1) {
            setIdentity(file);
            setStep(2);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [step]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [],
    },
    onDrop,
    onError: (error) => {
      console.log(error);
    },
  });
  const toast = useToast();
  const handleSubmit = async () => {
    userVerification(dispatch, { identity, selfie });
  };

  const breadcrumbItems = [
    {
      name: "Accueil",
      path: "/",
    },
    {
      name: "Account",
      path: "/account",
    },
    {
      name: "Verification",
      path: "/verification",
    },
  ];
  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />
      <Container my={10}>
        <Text fontWeight={700} fontSize={30}>
          Vérification
        </Text>
        <Text>
          Pour des raisons de sécurité, nous avons besoin de vérifier votre
          identité.
        </Text>
        {!user.is_verified ? (
          user.verification_status === "pending" ? (
            <Container my={10}>
              <Text fontWeight={700} fontSize={20}>
                Votre vérification est en cours ⌛️
              </Text>
            </Container>
          ) : (
            <>
              <Container my={10} gap={4} display={"flex"}>
                <div
                  style={{
                    border: "2px solid #9ae6b4",
                    width: "50%",
                    padding: "20px",
                    minHeight: "30vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "5px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={
                      identity ? identity.original_url : "/images/identity.png"
                    }
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      borderRadius: "10px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="identity"
                  />
                  <Text>{"Pièce d'identité"}</Text>
                </div>
                <div
                  style={{
                    border: "2px solid #9ae6b4",
                    width: "50%",
                    padding: "20px",
                    minHeight: "30vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "5px",
                    textAlign: "center",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={selfie ? selfie.original_url : "/images/selfie.png"}
                    alt="selfie"
                    style={{
                      padding: "10px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                  <Text>Photo de vous tenant votre pièce d'identité</Text>
                </div>
              </Container>
              <Container my={10}>
                <Text fontWeight={700} fontSize={20}>
                  Étape {step}
                </Text>
                <Text>
                  {step === 1
                    ? "Veuillez fournir une pièce d'identité (Carte Nationale d'Identité ou récépissé, passeport ou permis de conduire)"
                    : "Veuillez fournir une photo de vous tenant votre pièce d'identité"}
                </Text>
                <Container
                  my={10}
                  {...getRootProps()}
                  style={{
                    border: "2px dashed #9ae6b4",
                    padding: "20px",
                    textAlign: "center",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Text>Déposez votre fichier ici ...</Text>
                  ) : (
                    <Text>
                      Cliquez ou glissez pour déposer votre fichier ici
                    </Text>
                  )}
                </Container>
                {identity && <Text>{identity.name}</Text>}
                {selfie && <Text>{selfie.name}</Text>}
                <Container my={10}>
                  {step === 2 && (
                    <Button
                      isLoading={loading}
                      size="lg"
                      mt={5}
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      Retour à l'étape précédente
                    </Button>
                  )}
                  <Button
                    isLoading={loading}
                    colorScheme="whatsapp"
                    size="lg"
                    mt={5}
                    onClick={() =>
                      step === 2
                        ? selfie && identity
                          ? handleSubmit()
                          : toast({
                              title: "Erreur",
                              description:
                                "Veuillez fournir une pièce d'identité et une photo de vous tenant votre pièce d'identité",
                              status: "error",
                              duration: 9000,
                              isClosable: true,
                            })
                        : identity
                        ? setStep(step + 1)
                        : toast({
                            title: "Erreur",
                            description:
                              "Veuillez fournir une pièce d'identité",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          })
                    }
                  >
                    {step === 1
                      ? "Étape suivante"
                      : "Terminer la vérification d'identité"}
                  </Button>
                </Container>
              </Container>
            </>
          )
        ) : (
          <Container my={10}>
            <Text fontWeight={700} fontSize={20}>
              Votre vérification a été approuvée ✅
            </Text>
          </Container>
        )}
      </Container>
    </Layout>
  );
}

export default Verification;

import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import LoadingPage from "../../pages/LoadingPage";
import { getUser, useGlobalDispatch, useGlobalState } from "../../store/GlobalStore";
import ErrorBox from "../global/ErrorBox";
import { Button } from "@chakra-ui/react";

function PrivateRoute() {
  const { error, user } = useGlobalState();
  const dispatch = useGlobalDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUser(dispatch);
    }
  }, []);

  if (localStorage.getItem("token")) {
    if (user) {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/login" />;
  }
  if (!user && error) {

    return (
      <>
        <ErrorBox
          error={{
            message: "Une erreur est survenue chez nous. Veuillez recharger la page ou contactez-nous au +237 6 95 76 25 95 (Whatsapp Uniquement). Erreur : "+error.message,
          }}
        />
        <Button onClick={() => {
          localStorage.removeItem("token");
          window.location.reload();
        }}>
          Recharger la page
        </Button>
      </>
    );
  }
  return <LoadingPage />;
}

export default PrivateRoute;
